/**
 * @generated SignedSource<<b119bbe53557528d93f159e8886a1852>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GetAzureAdAuthenticationUrlForEmailInput = {
  clientMutationId?: string | null;
  codeChallenge: string;
  email: string;
};
export type loginForm_GetAzureAdAuthenticationUrlForEmailMutation$variables = {
  input: GetAzureAdAuthenticationUrlForEmailInput;
};
export type loginForm_GetAzureAdAuthenticationUrlForEmailMutation$data = {
  readonly AuthAzureAd: {
    readonly getAzureAdAuthenticationUrlForEmail: {
      readonly redirectUri: string;
    } | null;
  };
};
export type loginForm_GetAzureAdAuthenticationUrlForEmailMutation = {
  response: loginForm_GetAzureAdAuthenticationUrlForEmailMutation$data;
  variables: loginForm_GetAzureAdAuthenticationUrlForEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthAzureAdMutations",
    "kind": "LinkedField",
    "name": "AuthAzureAd",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "GetAzureAdAuthenticationUrlForEmailPayload",
        "kind": "LinkedField",
        "name": "getAzureAdAuthenticationUrlForEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "redirectUri",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loginForm_GetAzureAdAuthenticationUrlForEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginForm_GetAzureAdAuthenticationUrlForEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a4147ce21892a83348102d78670400cd",
    "id": null,
    "metadata": {},
    "name": "loginForm_GetAzureAdAuthenticationUrlForEmailMutation",
    "operationKind": "mutation",
    "text": "mutation loginForm_GetAzureAdAuthenticationUrlForEmailMutation(\n  $input: GetAzureAdAuthenticationUrlForEmailInput!\n) {\n  AuthAzureAd {\n    getAzureAdAuthenticationUrlForEmail(input: $input) {\n      redirectUri\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "59f441ac4c4499d1106f8cc703abb881";

export default node;
