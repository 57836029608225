import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment editAzureAdSsoConfigurationForm_QueryFragment on Query {
		Viewer {
			Auth {
				currentAccount {
					settings {
						force2FA
						authProviderConfigs {
							... on AzureAdAuthProviderConfig {
								isActivated
								applicationId
								authorityUrl
								domain
								tenantId
								secret
								kind
							}
						}
					}
				}
			}
		}
	}
`;

export const SET_AZURE_AD_SSO_CONFIGURATION_MUTATION = graphql`
	mutation editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation(
		$input: SetAzureAdSsoConfigurationInput!
	) {
		Auth {
			setAzureAdSsoConfiguration(input: $input) {
				account {
					settings {
						authProviderConfigs {
							... on AzureAdAuthProviderConfig {
								tenantId
								applicationId
								domain
								authorityUrl
								secret
								isActivated
							}
						}
					}
				}
			}
		}
	}
`;
