/**
 * @generated SignedSource<<29ba8db043e049e031e2d018989d0dc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetAzureAdSsoConfigurationInput = {
  azureAdAuthProviderConfig?: AzureAdAuthProviderConfigInput | null;
  clientMutationId?: string | null;
};
export type AzureAdAuthProviderConfigInput = {
  applicationId: string;
  authorityUrl: string;
  domain: string;
  isActivated: boolean;
  secret: string;
  tenantId: string;
};
export type editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation$variables = {
  input: SetAzureAdSsoConfigurationInput;
};
export type editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation$data = {
  readonly Auth: {
    readonly setAzureAdSsoConfiguration: {
      readonly account: {
        readonly settings: {
          readonly authProviderConfigs: ReadonlyArray<{
            readonly applicationId?: string;
            readonly authorityUrl?: string;
            readonly domain?: string;
            readonly isActivated?: boolean;
            readonly secret?: string;
            readonly tenantId?: string;
          }>;
        };
      };
    } | null;
  };
};
export type editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation = {
  response: editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation$data;
  variables: editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tenantId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorityUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secret",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActivated",
      "storageKey": null
    }
  ],
  "type": "AzureAdAuthProviderConfig",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetAzureAdSsoConfigurationPayload",
            "kind": "LinkedField",
            "name": "setAzureAdSsoConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountSettings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "authProviderConfigs",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetAzureAdSsoConfigurationPayload",
            "kind": "LinkedField",
            "name": "setAzureAdSsoConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountSettings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "authProviderConfigs",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aefb5a932c86e242528179bf017d401f",
    "id": null,
    "metadata": {},
    "name": "editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation",
    "operationKind": "mutation",
    "text": "mutation editAzureAdSsoConfigurationForm_SetAzureAdSsoConfigurationMutation(\n  $input: SetAzureAdSsoConfigurationInput!\n) {\n  Auth {\n    setAzureAdSsoConfiguration(input: $input) {\n      account {\n        settings {\n          authProviderConfigs {\n            __typename\n            ... on AzureAdAuthProviderConfig {\n              tenantId\n              applicationId\n              domain\n              authorityUrl\n              secret\n              isActivated\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1b9c7305c226b5d4776af6a767e756f";

export default node;
