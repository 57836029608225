/**
 * @generated SignedSource<<4e731ad26d44dbaa022426f0abf56f49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type staffViewFiltersPart_QueryFragment$data = {
  readonly Assignments: {
    readonly AssignmentRoles: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        };
      } | null> | null;
    };
  };
  readonly Division: {
    readonly Divisions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        };
      } | null> | null;
    };
  };
  readonly Region: {
    readonly Regions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "staffViewFiltersPart_QueryFragment";
};
export type staffViewFiltersPart_QueryFragment$key = {
  readonly " $data"?: staffViewFiltersPart_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"staffViewFiltersPart_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "staffViewFiltersPart_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentsQueries",
      "kind": "LinkedField",
      "name": "Assignments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentRolesConnection",
          "kind": "LinkedField",
          "name": "AssignmentRoles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssignmentRolesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssignmentRole",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DivisionQueries",
      "kind": "LinkedField",
      "name": "Division",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DivisionsConnection",
          "kind": "LinkedField",
          "name": "Divisions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DivisionsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Division",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RegionQueries",
      "kind": "LinkedField",
      "name": "Region",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RegionsConnection",
          "kind": "LinkedField",
          "name": "Regions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RegionsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Region",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2483710d7e7fa6ac03bb0d25a0cfd4fa";

export default node;
