/**
 * @generated SignedSource<<e9f9c74d9f6e9d21f78a9f0acd62b232>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountSettingsScreen_Query$variables = {};
export type accountSettingsScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChangeLogoForm_CurrentUser" | "editAccountSettingsForm_QueryFragment" | "editAssessmentPasswordForm_QueryFragment" | "editAzureAdSsoConfigurationForm_QueryFragment" | "editNumericalDimensionExplanationsForm_QueryFragment">;
};
export type accountSettingsScreen_Query = {
  response: accountSettingsScreen_Query$data;
  variables: accountSettingsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountSettingsScreen_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ChangeLogoForm_CurrentUser"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "editNumericalDimensionExplanationsForm_QueryFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "editAccountSettingsForm_QueryFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "editAssessmentPasswordForm_QueryFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "editAzureAdSsoConfigurationForm_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountSettingsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "currentAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "logo",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numericalDimensionExplanations",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "assessmentPassword",
                            "storageKey": null
                          }
                        ],
                        "type": "HarkinsAccountExtensionAndId",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountSettings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "force2FA",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "authProviderConfigs",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isActivated",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "applicationId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "authorityUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "domain",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tenantId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "secret",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              }
                            ],
                            "type": "AzureAdAuthProviderConfig",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "786d23bb455fe908777359f782c92e5e",
    "id": null,
    "metadata": {},
    "name": "accountSettingsScreen_Query",
    "operationKind": "query",
    "text": "query accountSettingsScreen_Query {\n  ...ChangeLogoForm_CurrentUser\n  ...editNumericalDimensionExplanationsForm_QueryFragment\n  ...editAccountSettingsForm_QueryFragment\n  ...editAssessmentPasswordForm_QueryFragment\n  ...editAzureAdSsoConfigurationForm_QueryFragment\n}\n\nfragment ChangeLogoForm_CurrentUser on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        extension {\n          __typename\n          ... on HarkinsAccountExtensionAndId {\n            logo {\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment editAccountSettingsForm_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        settings {\n          force2FA\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment editAssessmentPasswordForm_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        extension {\n          __typename\n          ... on HarkinsAccountExtensionAndId {\n            assessmentPassword\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment editAzureAdSsoConfigurationForm_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        settings {\n          force2FA\n          authProviderConfigs {\n            __typename\n            ... on AzureAdAuthProviderConfig {\n              isActivated\n              applicationId\n              authorityUrl\n              domain\n              tenantId\n              secret\n              kind\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment editNumericalDimensionExplanationsForm_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        extension {\n          __typename\n          ... on HarkinsAccountExtensionAndId {\n            numericalDimensionExplanations\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "203e6ba878c53d99e605c409e9de4518";

export default node;
