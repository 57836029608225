/**
 * @generated SignedSource<<5bddfa1261d380ca852ce46fa185eb56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailHasAzureAdSsoSetupInput = {
  clientMutationId?: string | null;
  email: string;
};
export type loginForm_EmailHasAzureADSSOSetupMutation$variables = {
  input: EmailHasAzureAdSsoSetupInput;
};
export type loginForm_EmailHasAzureADSSOSetupMutation$data = {
  readonly AuthAzureAd: {
    readonly emailHasAzureAdSsoSetup: {
      readonly hasSetup: boolean;
    } | null;
  };
};
export type loginForm_EmailHasAzureADSSOSetupMutation = {
  response: loginForm_EmailHasAzureADSSOSetupMutation$data;
  variables: loginForm_EmailHasAzureADSSOSetupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthAzureAdMutations",
    "kind": "LinkedField",
    "name": "AuthAzureAd",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "EmailHasAzureAdSsoSetupPayload",
        "kind": "LinkedField",
        "name": "emailHasAzureAdSsoSetup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasSetup",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loginForm_EmailHasAzureADSSOSetupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginForm_EmailHasAzureADSSOSetupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d25f181633806c3ea9c7c6bde8baf96a",
    "id": null,
    "metadata": {},
    "name": "loginForm_EmailHasAzureADSSOSetupMutation",
    "operationKind": "mutation",
    "text": "mutation loginForm_EmailHasAzureADSSOSetupMutation(\n  $input: EmailHasAzureAdSsoSetupInput!\n) {\n  AuthAzureAd {\n    emailHasAzureAdSsoSetup(input: $input) {\n      hasSetup\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9478af8998cb6597992b89bad91ce9e2";

export default node;
