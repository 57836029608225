/**
 * @generated SignedSource<<1d7f885801ff75217bfb2c6baabcc694>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenerateAvailabilityForecastInput = {
  clientMutationId?: string | null;
  countPossibleUtilizationNotPeople: boolean;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  filterByStagesOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  rows: ReadonlyArray<ForecastReportRow>;
  scenarioId: string;
  showProjects: boolean;
  toOpt?: any | null;
};
export type ForecastReportRow = {
  rolesRef: ReadonlyArray<string>;
};
export type GenerateAvailabilityForecastForm_GenerateAvailabilityForecastMutation$variables = {
  input: GenerateAvailabilityForecastInput;
};
export type GenerateAvailabilityForecastForm_GenerateAvailabilityForecastMutation$data = {
  readonly Availabilityforecast: {
    readonly generateAvailabilityForecast: {
      readonly availabilityForecast: {
        readonly countPossibleUtilizationNotPeople: boolean;
        readonly rows: ReadonlyArray<{
          readonly columns: ReadonlyArray<{
            readonly available: number;
            readonly availablePeople: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
            }>;
            readonly difference: number;
            readonly needed: number;
            readonly projects: number;
            readonly yearMonth: any;
          }>;
          readonly roles: ReadonlyArray<{
            readonly name: string;
          }>;
        }>;
        readonly summary: ReadonlyArray<{
          readonly available: number;
          readonly difference: number;
          readonly needed: number;
          readonly projects: number;
          readonly yearMonth: any;
        }>;
        readonly yearAndMonths: ReadonlyArray<any>;
      };
    } | null;
  };
};
export type GenerateAvailabilityForecastForm_GenerateAvailabilityForecastMutation = {
  response: GenerateAvailabilityForecastForm_GenerateAvailabilityForecastMutation$data;
  variables: GenerateAvailabilityForecastForm_GenerateAvailabilityForecastMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "available",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difference",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needed",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yearMonth",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projects",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ForecastColumn",
  "kind": "LinkedField",
  "name": "columns",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "availablePeople",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ForecastColumn",
  "kind": "LinkedField",
  "name": "summary",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v4/*: any*/),
    (v3/*: any*/),
    (v2/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yearAndMonths",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countPossibleUtilizationNotPeople",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateAvailabilityForecastForm_GenerateAvailabilityForecastMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AvailabilityforecastMutations",
        "kind": "LinkedField",
        "name": "Availabilityforecast",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GenerateAvailabilityForecastPayload",
            "kind": "LinkedField",
            "name": "generateAvailabilityForecast",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AvailabilityForecast",
                "kind": "LinkedField",
                "name": "availabilityForecast",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ForecastRow",
                    "kind": "LinkedField",
                    "name": "rows",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentRole",
                        "kind": "LinkedField",
                        "name": "roles",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateAvailabilityForecastForm_GenerateAvailabilityForecastMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AvailabilityforecastMutations",
        "kind": "LinkedField",
        "name": "Availabilityforecast",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GenerateAvailabilityForecastPayload",
            "kind": "LinkedField",
            "name": "generateAvailabilityForecast",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AvailabilityForecast",
                "kind": "LinkedField",
                "name": "availabilityForecast",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ForecastRow",
                    "kind": "LinkedField",
                    "name": "rows",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentRole",
                        "kind": "LinkedField",
                        "name": "roles",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3386f1c672dec20f3a70a4872cfdecc5",
    "id": null,
    "metadata": {},
    "name": "GenerateAvailabilityForecastForm_GenerateAvailabilityForecastMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateAvailabilityForecastForm_GenerateAvailabilityForecastMutation(\n  $input: GenerateAvailabilityForecastInput!\n) {\n  Availabilityforecast {\n    generateAvailabilityForecast(input: $input) {\n      availabilityForecast {\n        rows {\n          columns {\n            available\n            difference\n            needed\n            availablePeople {\n              id\n              name\n            }\n            yearMonth\n            projects\n          }\n          roles {\n            name\n            id\n          }\n        }\n        summary {\n          yearMonth\n          needed\n          difference\n          available\n          projects\n        }\n        yearAndMonths\n        countPossibleUtilizationNotPeople\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3bcc2c74a41f34316c688bd8458bf5c";

export default node;
