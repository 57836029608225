import pkceChallenge from "pkce-challenge";
import { AZURE_AD_AUTH_CODE_VERIFIER } from "@screens/login/parts/login-form/login-form.consts";

/**
 * Generate PKCE code challenge and code verifier, saving code-verifier to local storage
 * @returns code-challenge
 */
export const generateAndSaveChallenge = async () => {
	const challenge = await pkceChallenge(128);
	localStorage.setItem(AZURE_AD_AUTH_CODE_VERIFIER, challenge.code_verifier);
	return challenge.code_challenge;
};

/**
 * Clean up local storage.
 */
export const cleanAzureAdLocalStorage = () => {
	localStorage.removeItem(AZURE_AD_AUTH_CODE_VERIFIER);
};

/**
 * Get code-verifier for azure ad authorization.
 */
export const getAzureAdCodeVerifier = () => localStorage.getItem(AZURE_AD_AUTH_CODE_VERIFIER);
