/**
 * @generated SignedSource<<e9bef10ac845d0a53c8bab6af49f255f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginAzureAdUserWithHarkinsUserExtensionInput = {
  clientMutationId?: string | null;
  code: string;
  codeVerifier: string;
  email: string;
};
export type loginForm_LoginAzureAdUserMutation$variables = {
  input: LoginAzureAdUserWithHarkinsUserExtensionInput;
};
export type loginForm_LoginAzureAdUserMutation$data = {
  readonly Sso: {
    readonly loginAzureAdUserWithHarkinsUserExtension: {
      readonly jwtTokens: {
        readonly accessToken: string;
        readonly refreshToken: string;
      };
    } | null;
  };
};
export type loginForm_LoginAzureAdUserMutation = {
  response: loginForm_LoginAzureAdUserMutation$data;
  variables: loginForm_LoginAzureAdUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SsoMutations",
    "kind": "LinkedField",
    "name": "Sso",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "LoginAzureAdUserWithHarkinsUserExtensionPayload",
        "kind": "LinkedField",
        "name": "loginAzureAdUserWithHarkinsUserExtension",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JwtLoginInformation",
            "kind": "LinkedField",
            "name": "jwtTokens",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessToken",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refreshToken",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loginForm_LoginAzureAdUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginForm_LoginAzureAdUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "efd21a727f493b8dafba24a985581395",
    "id": null,
    "metadata": {},
    "name": "loginForm_LoginAzureAdUserMutation",
    "operationKind": "mutation",
    "text": "mutation loginForm_LoginAzureAdUserMutation(\n  $input: LoginAzureAdUserWithHarkinsUserExtensionInput!\n) {\n  Sso {\n    loginAzureAdUserWithHarkinsUserExtension(input: $input) {\n      jwtTokens {\n        accessToken\n        refreshToken\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f042965b17a2cdbbd6e724971d0a6ea";

export default node;
